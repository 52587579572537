:root {
  --font-color: #221600;
  --lighter-font-color: #412e0c;
  --inactive: #96b8cc;
  --external-background-color: #f3f3f3;
  --internal-background-color: #fff;
  --widget-background-color: #fcfafa;
  --important-background-color: #f0f4f8;
}

.light-theme {
  --font-color: #221600;
  --lighter-font-color: #412e0c;
  --inactive: #96b8cc;
  --external-background-color: #fffff2;
  --internal-background-color: #fff;
  --widget-background-color: #fcfafa;
  --important-background-color: #f0f4f8;
  --selected-color: #ddd5b1;
}

.dark-theme {
  --font-color: #e6e6e6;
  --lighter-font-color: #cecaca;
  --inactive: #7c7c7c;
  --external-background-color: #202d4e;
  --internal-background-color: #0E172D;
  --widget-background-color: #101c3a;
  --important-background-color: #202b46;
}

.dark-theme #updated {
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
}

body, main, #root {
  margin: 0;
  background-color: var(--internal-background-color);
  height: 100%;
  max-width: 100%;
  padding: 0;
  font-family: 'Montserrat';
}

a {
  color: var(--font-color);
  text-decoration: none;
}

.copy-click {
  cursor: pointer;
}

.loading {
  height: 100%;
  width: 100%;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
}

.loading img {
  text-align: center;
}

.buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 15px;
}

.button {
  padding: 5px 20px 5px 20px;
  border: solid 1px var(--font-color);
  font-weight: 700;
  border-radius: 10px;
  background-color: var(--external-background-color);
  margin: 5px auto;
  cursor: pointer;
  margin-right: 25px;
  margin-left: 25px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  text-align: center;
}

.button.active {
  border: solid 2px;
  font-weight: 900;
  background-color: var(--selected-color);
}

.sidebar {
  position: fixed;
  top: 80px;
  left: 0;
  bottom: 0;
  width: 200px;
  -webkit-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: var(--external-background-color);
  -webkit-box-shadow: 2px 0 5px -5px rgba(0, 0, 0, 0.5);
          box-shadow: 2px 0 5px -5px rgba(0, 0, 0, 0.5);
  /* Media query for small screens */
}

.sidebar-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.collapsed .sidebar-icon {
  width: 100%;
}

.sidebar-btn {
  font-size: 25px;
  padding: 20px;
  color: var(--font-color);
  cursor: pointer;
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;
}

.sidebar-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  padding: 0;
  margin: 0;
  height: 100%;
  padding-bottom: 20vh;
}

.sidebar-content a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-decoration: none;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  width: 100%;
  -ms-flex-item-align: stretch;
      align-self: stretch;
}

.sidebar-content a i {
  font-size: 2.5vh;
  margin-right: 10px;
  color: var(--font-color);
}

.sidebar-bottom {
  margin-bottom: 50px;
  text-align: center;
}

.sidebar-bottom i {
  color: var(--font-color);
  font-size: 20px;
}

.sidebar-text {
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 800;
  color: var(--font-color);
  display: inline-block;
}

.collapsed .sidebar-text {
  display: none;
}

.sidebar.collapsed {
  width: 80px;
  overflow: hidden;
}

.sidebar.collapsed-icon {
  width: 100%;
}

.sidebar.collapsed-text {
  display: none;
}

@media screen and (max-width: 768px) {
  .sidebar {
    width: 100%;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  .sidebar.collapsed {
    width: 50px;
  }
}

.rectangle {
  position: fixed;
  top: 0;
  left: 0;
  height: 80px;
  width: 200px;
  z-index: 5;
  background-color: var(--external-background-color);
  -webkit-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
}

.rectangle.collapsed {
  width: 80px;
}

.logo {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 10px;
}

.topbar {
  position: fixed;
  top: 0;
  left: 200px;
  right: 0;
  height: 80px;
  background-color: var(--external-background-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0px 20px 0px 10px;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  -webkit-transition: left 0.3s ease-in-out;
  transition: left 0.3s ease-in-out;
  z-index: 2;
}

.topbar.collapsed {
  left: 80px;
}

.topbar input[type="text"] {
  border: 1px solid grey;
  outline: none;
  width: 30vw;
  padding: 10px;
  border-radius: 8px;
  background-color: var(--internal-background-color);
  margin-right: 10px;
  font-family: 'Montserrat';
}

.topbar input[type="text"]::-webkit-input-placeholder {
  font-family: 'Montserrat';
  color: var(--font-color);
}

.topbar input[type="text"]:-ms-input-placeholder {
  font-family: 'Montserrat';
  color: var(--font-color);
}

.topbar input[type="text"]::-ms-input-placeholder {
  font-family: 'Montserrat';
  color: var(--font-color);
}

.topbar input[type="text"]::placeholder {
  font-family: 'Montserrat';
  color: var(--font-color);
}

.topbar .search-btn {
  border: none;
  -webkit-transform: translateX(-50px);
          transform: translateX(-50px);
  background-color: var(--internal-background-color);
  cursor: pointer;
}

.topbar .search-btn i {
  color: var(--font-color);
}

.topbar .top-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.topbar .top-right p {
  font-family: 'Montserrat';
  color: var(--font-color);
  font-weight: 600;
  font-size: 14px;
  margin-right: 10px;
  cursor: pointer;
}

.topbar .top-right img {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border: 3px solid var(--internal-background-color);
  margin-right: 30px;
  cursor: pointer;
}

.topbar .top-right .icons {
  width: 120px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.topbar .top-right .icons .fa-moon, .topbar .top-right .icons .fa-bell, .topbar .top-right .icons .fa-sun {
  cursor: pointer;
  font-size: 16px;
  color: var(--font-color);
}

.topbar .top-right .icons .fa-sign-out-alt {
  font-size: 24px;
  color: var(--font-color);
}

.topbar .top-right .icons .notification-icon {
  cursor: pointer;
  position: relative;
}

.topbar .top-right .icons .notification-icon::after {
  content: attr(data-count);
  position: absolute;
  top: -5px;
  right: -5px;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  background-color: #f04e4e;
  border-radius: 50%;
  color: white;
  font-size: 12px;
}

.main {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: absolute;
  top: 80px;
  left: 200px;
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: calc(100% - 80px);
  width: calc(100% - 200px);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.main.collapsed {
  left: 80px;
  width: calc(100% - 80px);
}

.Toastify__toast--success.custom-toast {
  background-color: #e6ffe6;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 0.6vw;
}

.Toastify__toast--error.custom-toast {
  background-color: #ffc2c2;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 0.6vw;
}

.container {
  position: relative;
  width: 90vw;
  /* Adjust the width as needed */
  height: 100%;
  padding: 20px;
  margin: auto;
  margin-top: 150px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.tabs-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px 10px 0 0;
  position: absolute;
  top: -45px;
  left: 0;
  right: 0;
}

.tab {
  padding: 10px 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border-radius: 10px 10px 0 0;
  width: 20vw;
  text-align: center;
  color: var(--font-color);
  font-family: 'Montserrat';
  font-weight: 700;
}

.tab:hover {
  font-weight: 600;
}

.tab.active {
  background-color: var(--selected-color);
}

.content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  width: 100%;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: auto;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

.content.game {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.header {
  width: 130px;
  height: 130px;
  background-color: #fff;
  margin: 10px;
  border-radius: 25px;
  border: 1px solid var(--selected-color);
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.header.large {
  height: 200px;
}

.header .info {
  font-size: 0.8em;
}

.header .goal {
  text-align: center;
  font-size: 50px;
  font-weight: 800;
}

.header-logo {
  max-width: 100px;
  max-height: 100px;
  -o-object-fit: contain;
     object-fit: contain;
}

.header-title {
  font-weight: 800;
  margin-top: 10px;
  text-align: center;
}

.game-header {
  -ms-flex-pack: distribute;
      justify-content: space-around;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.pagin-top {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.pagin-top i {
  margin: 10px;
  cursor: pointer;
}

.pagin-top .number {
  margin: 10px;
}

.league-choice {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 80%;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: 20%;
  margin-right: 20%;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.league-choice img {
  height: 50px;
  width: 50px;
  border-radius: 30%;
  border: 1px solid gray;
  cursor: pointer;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 5px;
  -o-object-fit: contain;
     object-fit: contain;
}

.league-choice img.active {
  border: 3px solid #3a4475;
}

.table {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border: 1px solid var(--external-background-color);
  border-radius: 8px;
  overflow: hidden;
  width: 90%;
}

.table.small {
  max-height: 50%;
  min-height: 50%;
}

.table .table-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: var(--external-background-color);
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 0.8em;
  text-transform: uppercase;
}

.table .table-header .sm-txt {
  font-size: 0.72em;
}

.table .table-header .sortable {
  cursor: pointer;
}

.table .table-content {
  overflow-y: auto;
}

.table .cell {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 70px;
          flex: 0 0 70px;
  text-align: center;
}

.table .cell.live a {
  color: red;
  font-weight: 700;
}

.table .md-cell {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 130px;
          flex: 0 0 130px;
  text-align: center;
}

.table .lg-cell {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 200px;
          flex: 0 0 200px;
  text-align: center;
}

.table .line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  font-weight: 500;
  font-size: 0.7em;
  padding-left: 5px;
  padding-right: 5px;
}

.table .line i {
  margin-left: 5px;
}

.table .line i.yellow {
  color: #e9b50a;
}

.table .line i.red {
  color: #a30404;
}

.table .line i.green {
  color: #439600;
}

.lineups {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.lineups .pos {
  background-color: #0E172D;
  font-size: 11px;
  color: white;
  border-radius: 50%;
  min-width: 10px;
  min-height: 10px;
  padding: 6px  8px  6px  8px;
  margin-right: 10px;
  font-weight: 700;
  text-align: center;
  font-family: monospace;
}

.lineups .pos.coach {
  background-color: #c9a505;
}

.lineups .lineup {
  width: 250px;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 30px;
  font-weight: 600;
  font-size: 13px;
  -webkit-box-pack: top;
      -ms-flex-pack: top;
          justify-content: top;
  flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 25px;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.lineups .lineup .coach {
  text-align: center;
}

.lineups .lineup div {
  margin-bottom: 10px;
}

.lineups .lineup i {
  margin-left: 5px;
}

.lineups .lineup i.yellow {
  color: #e9b50a;
}

.lineups .lineup i.red {
  color: #a30404;
}

.lineups .lineup i.green {
  color: #439600;
}

.lineups .game-stats {
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 12px;
  font-weight: 700;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 30px;
  display: flex;
  -webkit-box-pack: top;
      -ms-flex-pack: top;
          justify-content: top;
  flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 25px;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.lineups .game-stats i {
  font-size: 30px;
  font-weight: 900;
  margin-bottom: 15px;
}

.lineups .game-stats .game-stat {
  margin-left: 15px;
  margin-right: 15px;
  color: rgba(0, 0, 0, 0.6);
}

.generic-table {
  overflow: hidden;
  max-height: 100%;
  min-height: 100%;
}

.generic-table div {
  text-align: center;
}

.generic-table div.sm {
  width: 50px;
}

.generic-table div.md {
  width: 150px;
}

.generic-table div.lg {
  width: 300px;
}

.generic-table div.xl {
  width: 400px;
}

.generic-table .table-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  background-color: var(--selected-color);
  font-family: 'Montserrat';
  font-weight: 800;
  font-size: 0.7em;
  text-transform: uppercase;
  color: var(--font-color);
  padding: 5px;
}

.generic-table .table-header div {
  cursor: pointer;
}

.generic-table .table-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow-y: auto;
  height: 95%;
}

.generic-table .table-body .table-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  font-family: 'Montserrat';
  font-weight: 500;
  color: var(--font-color);
  background-color: var(--widget-background-color);
  font-size: 0.7em;
}

:root {
    --font-color: #221600; //var(--font-color) => #00253B
    --lighter-font-color: #412e0c;
    --inactive: #96b8cc;
    --external-background-color: #f3f3f3; //var(--external-background-color) => #f3f3f3
    --internal-background-color: #fff; //var(--internal-background-color) => #fff
    --widget-background-color: #fcfafa; // var(--widget-background-color) => #fcfafa
    --important-background-color: #f0f4f8;
  }
  
  .light-theme {
    --font-color: #221600; //var(--font-color) => #00253B
    --lighter-font-color: #412e0c;
    --inactive: #96b8cc;
    --external-background-color: #fffff2; //var(--external-background-color) => #f3f3f3
    --internal-background-color: #fff; //var(--internal-background-color) => #fff
    --widget-background-color: #fcfafa; // var(--widget-background-color) => #fcfafa
    --important-background-color: #f0f4f8;
    --selected-color: #ddd5b1;
  }
  
  .dark-theme {
    --font-color: #e6e6e6; //var(--font-color) => #00253B
    --lighter-font-color: #cecaca;
    --inactive: #7c7c7c;
    --external-background-color: #202d4e; //var(--external-background-color) => #f3f3f3
    --internal-background-color: #0E172D; //var(--internal-background-color) => #fff
    --widget-background-color: #101c3a; // var(--widget-background-color) => #fcfafa
    --important-background-color: #202b46;
  

    #updated {
      filter: brightness(0) invert(1);
    }
  }
  
  
  body, main, #root {
    margin: 0;
    background-color: var(--internal-background-color);
    height: 100%;
    max-width: 100%;
    padding: 0;
    font-family: 'Montserrat';
  }
  
  a {
    color: var(--font-color);
    text-decoration: none;
  }
  
  .copy-click {
    cursor: pointer;
  }

  .loading {
    height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    
    img {
      text-align: center;
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 15px
  }

  .button {
    padding: 5px 20px 5px 20px;
    border: solid 1px var(--font-color);
    font-weight: 700;
    border-radius: 10px;
    background-color: var(--external-background-color);
    margin: 5px auto;
    cursor: pointer;
    margin-right: 25px;
    margin-left: 25px;
    box-sizing: border-box;
    text-align: center;

    &.active {
      border: solid 2px;
      font-weight: 900;
      background-color: var(--selected-color);
    }
  }
  
  .sidebar {
    position: fixed;
    top: 80px;
    left: 0;
    bottom: 0;
    width: 200px;
    transition: width 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    background-color: var(--external-background-color); //--external-background-color
    box-shadow: 2px 0 5px -5px rgba(0, 0, 0, 0.5);
  
    &-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
  
      .collapsed & {
        width: 100%;
      }
    }
  
    &-btn {
      font-size: 25px;
      padding: 20px;
      color: var(--font-color);; //--font-color
      cursor: pointer;
      margin-top: auto;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 25px;
    }
  
    &-content {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      padding: 0;
      margin: 0;
      height: 100%;
      padding-bottom: 20vh;
  
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        flex-grow: 1;
        width: 100%;
        align-self: stretch;
  
        i {
          font-size: 2.5vh;
          margin-right: 10px;
          color: var(--font-color); //--font-color
        }
      }
  
    }
  
    &-bottom {
      margin-bottom: 50px;
      text-align: center;
      i {
        color: var(--font-color); //--font-color
        font-size: 20px;
      }
    }
  
    &-text {
      font-family: 'Montserrat';
      font-size: 16px;
      font-weight: 800;
      color: var(--font-color); //--font-color
      display: inline-block;
  
      .collapsed & {
        display: none;
      }
    }
  
    &.collapsed {
      width: 80px;
      overflow: hidden;
  
      &-icon {
        width: 100%;
      }
  
      &-text {
        display: none;
      }
    }
    
    /* Media query for small screens */
    @media screen and (max-width: 768px) {
      & {
        width: 100%;
        transform: translateX(-100%);
      }
  
      &.collapsed {
        width: 50px;
      }
    }
  }
  
  .rectangle {
    position: fixed;
    top: 0;
    left: 0;
    height: 80px;
    width: 200px;
    z-index: 5;
    background-color: var(--external-background-color); //--external-background-color
    transition: width 0.3s ease-in-out;
  
    &.collapsed {
      width: 80px;
    }
  }
  
  .logo {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .topbar {
    position: fixed;
    top: 0;
    left: 200px;
    right: 0;
    height: 80px;
    background-color: var(--external-background-color); //--external-background-color
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px 0px 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: left 0.3s ease-in-out;
    z-index: 2;
  
    &.collapsed {
      left: 80px;
    }
  
    input[type="text"] {
      border: 1px solid grey; //--external-background-color
      outline: none;
      width: 30vw;
      padding: 10px;
      border-radius: 8px;
      background-color: var(--internal-background-color); //--internal-background-color
      margin-right: 10px;
      font-family: 'Montserrat';
  
      &::placeholder {
        font-family: 'Montserrat';
        color: var(--font-color);
      }
    }
  
    .search-btn {
      border: none;
      transform: translateX(-50px);
      background-color: var(--internal-background-color); //--internal-background-color
      cursor: pointer;
  
      i {
        color: var(--font-color);
      }
    }
  
    .top-right {
      display: flex;
      align-items: center;
      justify-content: space-between;
      
  
      p {
        font-family: 'Montserrat';
        color:var(--font-color);
        font-weight: 600;
        font-size: 14px;
        margin-right: 10px;
        cursor: pointer;
      }
  
      img {
        width: 50px;
        height: 50px;
        border-radius: 50px;
        border: 3px solid var(--internal-background-color); //--internal-background-color
        margin-right: 30px;
        cursor: pointer;
      }
  
      .icons {
        width: 120px;
        display: flex;
        align-items: center;
        justify-content: space-between;
  
        .fa-moon, .fa-bell, .fa-sun {
            cursor: pointer;
          font-size: 16px;
          color: var(--font-color); //--font-color
        }
  
        .fa-sign-out-alt {
          font-size: 24px;
          color: var(--font-color); //--font-color
        }

        .notification-icon {
            cursor: pointer;
            position: relative;
          }
          
          .notification-icon::after {
            content: attr(data-count);
            position: absolute;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            line-height: 16px;
            text-align: center;
            background-color: rgb(240, 78, 78);
            border-radius: 50%;
            color: white;
            font-size: 12px;
          }
          
      }
    }
  }
  
  .main {
    box-sizing: border-box;
    position: absolute;
    top: 80px;
    left: 200px;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    height: calc(100% - 80px);
    width: calc(100% - 200px);
    transition: all 0.3s ease-in-out;
  
    &.collapsed {
      left: 80px;
      width: calc(100% - 80px);
    }
  
  }

  .Toastify__toast--success.custom-toast {
    background-color: rgb(230, 255, 230);
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 0.6vw;
  }

  .Toastify__toast--error.custom-toast {
    background-color: rgb(255, 194, 194);
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 0.6vw;
  }

  .container {
    position: relative;
    width: 90vw; /* Adjust the width as needed */
    height: 100%;
    padding: 20px;
    margin: auto;
    margin-top: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .tabs-container {
    display: flex;
    justify-content: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px 10px 0 0;
    position: absolute;
    top: -45px;
    left: 0;
    right: 0;
  }

  .tab {
    padding: 10px 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    cursor: pointer;
    user-select: none;
    border-radius: 10px 10px 0 0;
    width: 20vw;
    text-align: center;
    color: var(--font-color);
    font-family: 'Montserrat';
    font-weight: 700;

    &:hover {
        font-weight: 600;
    }
  }

  .tab.active {
    background-color: var(--selected-color); 
  }

  .content {
    display: flex;
    margin-top: 20px;
    width: 100%;
    flex-wrap: wrap;
    margin: auto;
    justify-content: space-evenly;

    &.game {
      flex-direction: column;
      justify-content: space-around;
    }
  }
  

  .header {
    width: 130px;
    height: 130px;
    background-color: #fff;
    margin: 10px;
    border-radius: 25px;
    border: 1px solid var(--selected-color); 
    padding: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column ;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);

    &.large {
      height: 200px;
    }

    .info {
      font-size: 0.8em;
    }

    .goal {
      text-align: center;
      font-size: 50px;
      font-weight: 800;
    }
  }
  .header-logo {
      max-width: 100px;
      max-height: 100px;
      object-fit: contain;
  }

  .header-title {
    font-weight: 800;
    margin-top: 10px;
    text-align: center;
}

.game-header {
  justify-content: space-around;
  display: flex;
  flex-direction: row;
}

.pagin-top {
  justify-content: center;
  display: flex;
  i {
    margin: 10px;
    cursor: pointer;
  }
  .number {
    margin:10px;
  }
}

.league-choice {
  display: flex;
  flex-direction: row;
  width: 80%;
  justify-content: space-around;
  align-items: center;
  margin-left: 20%;
  margin-right: 20%;
  flex-wrap: wrap;
  margin-top: -10%;

  img {
    height: 50px;
    width: 50px;
    border-radius: 30%;
    border: 1px solid gray;
    cursor: pointer;
    box-sizing: border-box;
    margin: 5px;
    object-fit: contain;

    &.active {
      border: 3px solid rgb(58, 68, 117);
    }
  }
}

.table {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--external-background-color); //--external-background-color
  border-radius: 8px;
  overflow: hidden;
  width: 90%;

  &.small {
    max-height: 50%;
    min-height: 50%;
  }

  .table-header {
    display: flex;
    align-items: center;
    background-color: var(--external-background-color); //--external-background-color
    font-family: 'Montserrat'; 
    font-weight: 700;
    font-size: 0.8em;
    text-transform: uppercase;
    .sm-txt {
      font-size: 0.72em;
    }

    .sortable {
      cursor: pointer;
    }
  }

  .table-content {
    overflow-y: auto;

  }
  .cell {
    flex: 0 0 70px; // Changed from flex: 1
    text-align: center;
    &.live a {
      color: red;
      font-weight: 700;
    }
  }
  
  .md-cell {
    flex: 0 0 130px; // Changed from flex: 1
    text-align: center;
  }
  .lg-cell {
    flex: 0 0 200px; // Changed from flex: 1
    text-align: center;
  }

  .line {
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-weight: 500;
    font-size: 0.7em;
    padding-left: 5px;
    padding-right: 5px;

    i {
      margin-left: 5px;
      &.yellow {
        color: rgb(233, 181, 10);
      }
      &.red {
        color: rgb(163, 4, 4);
      }
      &.green {
        color: rgb(67, 150, 0);
      }
    }
  }
}

.lineups {
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  .pos {
    background-color: #0E172D;
    font-size: 11px;
    color: white;
    border-radius: 50%;
    min-width: 10px;
    min-height: 10px;
    padding: 6px  8px  6px  8px ;
    margin-right: 10px;
    font-weight: 700;
    text-align: center;
    font-family: monospace;
    &.coach {
      background-color: #c9a505;
    }
  }

  .lineup  {
    width: 250px;
    background-color: #fff;
    display:flex;
    flex-direction: column;
    padding: 30px;
    font-weight: 600;
    font-size: 13px;
    justify-content: top;
    flex-direction: column ;
    align-items: center;
    border-radius: 25px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);

    .coach {text-align: center;}

    div {
      margin-bottom: 10px;
    }

    i {
      margin-left: 5px;
      &.yellow {
        color: rgb(233, 181, 10);
      }
      &.red {
        color: rgb(163, 4, 4);
      }
      &.green {
        color: rgb(67, 150, 0);
      }
    }

  }

  .game-stats {
    i {
      font-size: 30px;
      font-weight: 900;
      margin-bottom: 15px;
    }
    background-color: #fff;
    display:flex;
    font-size: 12px;
    font-weight: 700;
    flex-direction: column;
    padding: 30px;
    display: flex;
    justify-content: top;
    flex-direction: column ;
    align-items: center;
    border-radius: 25px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    .game-stat{
      margin-left: 15px;
      margin-right: 15px;
      color:rgba(0, 0, 0, 0.6);
    }
}
}

.generic-table {

  overflow: hidden;
  max-height: 100%;
  min-height: 100%;

  div {
    text-align: center;
    &.sm {width: 50px;}
    &.md {width: 150px;}
    &.lg {width: 300px;}
    &.xl {width: 400px;}
  }

.table-header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: var(--selected-color); //--external-background-color
  font-family: 'Montserrat'; 
  font-weight: 800;
  font-size: 0.7em;
  text-transform: uppercase;
  color: var(--font-color);
  padding: 5px;

  div {
    cursor: pointer;
  }

}

  .table-body {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 95%;

    .table-row {
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-family: 'Montserrat';
      font-weight: 500;
      color: var(--font-color); //--font-color
      background-color: var(--widget-background-color);
      font-size: 0.7em;
    }
  }
}